<template>
  <div>
    <v-card data-testid="container-debug-janus-servers">
      <v-toolbar>
        <v-card-title>Debug Info Janus servers</v-card-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <DataTableExtended
          ref="refTableJanuses"
          :headers="headers"
          :items="debugJanusServersList"
          sortable
          class="elevation-0 border pt-3"
          :loading="showTableLoader"
          :no-data-text="showTableLoader ? 'Loading…' : 'No data available'"
          :server-items-length="debugJanusServersListCount"
          v-on:init-table-data="getDebugJanusesInfo"
          @update-search="
            (newS) => {
              this.filterSearch = newS
            }
          "
          data-testid="table-debug-janus-servers"
        >
          <template v-slot:top>
            <v-row class="px-3">
              <v-col cols="12" md="3">
                <SelectSignalsAsync
                  v-model="selectedSignal"
                  outlined
                  label="Result from signal (s)"
                  :multiple="false"
                  item-text="data.name"
                  item-value="_key"
                  :error-messages="errors.collect(`form-signal-servers.backup`)"
                  data-vv-name="backup"
                  data-vv-validate-on="change"
                  data-vv-as="Backup"
                  field="backup"
                  :menu-props="{ maxHeight: 304 }"
                  :prependEmptyOption="false"
                  :prependCustomOptions="[
                    { _key: 'all', data: { name: 'All' } },
                  ]"
                  @change="getDebugJanusesInfo()"
                  data-testid="filter-signal"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="filterSearch"
                  outlined
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  :style="$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''"
                  clearable
                  data-testid="filter-search"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.data.name`]="{ item }">
            <span data-testid="name">
              {{ item.data.name }}
            </span>
          </template>
          <template v-slot:[`item.data.url`]="{ item }">
            <span data-testid="url">
              {{ item.data.url }}
            </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div
              v-if="typeof item.status === 'undefined'"
              class="primary--text"
            >
              <span class="overline">
                <v-progress-circular
                  :size="16"
                  indeterminate
                  color="primary"
                  class="mx-2"
                  data-testid="progress-loading"
                />
              </span>
              <span class="font-weight-regular">Loading</span>
            </div>

            <div
              v-else-if="janusIsUnknown(item)"
              class="opaced"
              data-testid="status"
            >
              <span class="overline">
                <i class="fa fa-circle mx-2"></i>
              </span>
              <span class="font-weight-regular">Unknown</span>
            </div>

            <div
              v-else
              :class="
                janusIsActive(item)
                  ? Pallette.statusText.active
                  : Pallette.statusText.notActive
              "
              data-testid="status"
            >
              <span class="overline" data-testid="status-icon">
                <i class="fa fa-circle mx-2"></i>
              </span>
              <span class="font-weight-regular" data-testid="status-text">{{
                janusIsActive(item) ? "Active" : "Inactive"
              }}</span>
            </div>
          </template>
          <template v-slot:[`item.info.cpuAverage`]="{ item }">
            <span data-testid="cpu-average">
              {{ showInfo(item, "cpuAverage") | toFixed }}
            </span>
          </template>
          <template v-slot:[`item.info.memUsed`]="{ item }">
            <span data-testid="mem-used">
              {{ showInfo(item, "memUsed") | toFixed }}
            </span>
          </template>
          <template v-slot:[`item.info.loadUnits`]="{ item }">
            <span data-testid="load-units">
              {{ showInfo(item, "loadUnits") | toFixed }}
            </span>
          </template>
          <template v-slot:[`item.info.netUsed`]="{ item }">
            <span data-testid="net-used">
              {{ showInfo(item, "netUsed") | toFixed }}
            </span>
          </template>
        </DataTableExtended>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import DataTableExtended from "@/components/table/DataTableExtended.vue"
import SelectSignalsAsync from "@/components/selects/SelectSignalsAsync"
import {
  SignalServerService,
  STATUS_SERVER_ACTIVE,
  STATUS_SERVER_UNKNOWN,
} from "@/common/signal.service"

export default {
  components: {
    DataTableExtended,
    SelectSignalsAsync,
  },

  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "left",
          value: "data.name",
          width: 200,
          sortable: true,
        },
        {
          text: "Status",
          align: "center",
          value: "status",
          width: 130,
          sortable: true,
        },
        {
          text: "Url",
          align: "left",
          value: "data.url",
          sortable: true,
        },
        {
          text: "Сpu Average",
          align: "left",
          value: "info.cpuAverage",
          sortable: true,
        },
        {
          text: "Mem Used",
          align: "left",
          value: "info.memUsed",
          sortable: true,
        },
        {
          text: "Load Units",
          align: "left",
          value: "info.loadUnits",
          sortable: true,
        },
        {
          text: "Net Used",
          align: "left",
          value: "info.netUsed",
          sortable: true,
        },
      ],
      selectedSignal: "all",
      filterSearch: "",
    }
  },

  watch: {
    filterSearch() {
      this.getDebugJanusesInfo()
    },
  },

  mounted: function () {
    this.getSignalServersList().then(() => {
      this.$store.dispatch("resetJanusesApiDebugInfo")
    })
  },

  methods: {
    getDebugJanusesInfo() {
      let self = this
      let getParams = self.$refs.refTableJanuses.getTableServerParams()
      if (self.filterSearch !== "") getParams.search = self.filterSearch
      let fetchData = {
        params: getParams,
      }
      self.$store.dispatch("debugJanusServersList", fetchData).then(() => {
        self.getApiJanusesDebug().then(() => {
          self.$store.dispatch("parseJanusesApiDebugInfo")
        })
      })
    },
    getSignalServersList: function () {
      return this.$store.dispatch("debugSignalServersList", {
        params: { p: 1, pp: 500 },
      })
    },
    // get info from all signals about janus debug
    getApiJanusesDebug() {
      let self = this
      return new Promise((resolve) => {
        self.$store.dispatch("resetJanusesApiDebugInfo").then(() => {
          let prms = []
          self.signalsFetch.forEach((signal) => {
            prms.push(self.oneSignalJanusApi(signal))
          })
          Promise.all(prms).then(() => {
            resolve()
          })
        })
      })
    },
    // get data from one signal about januses debug
    oneSignalJanusApi(signal) {
      let self = this
      return new Promise((resolve) => {
        SignalServerService.janusesStatuses(signal.data.url)
          .then((res) => {
            self.$store
              .dispatch("saveJanusesApiDebugInfo", res?.data?.data)
              .then(() => {
                resolve()
              })
          })
          .catch(({ response }) => {
            resolve()
            // console.error('Error--oneSignalJanusApi', response)
          })
      })
    },
    janusIsActive(item) {
      return item.status === STATUS_SERVER_ACTIVE
    },
    janusIsUnknown(item) {
      return item.status === STATUS_SERVER_UNKNOWN
    },
    showInfo(item, field) {
      return item.info && item.info[field] ? item.info[field] : ""
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
      debugJanusServersList: "debugJanusServersList",
      debugJanusServersListCount: "debugJanusServersListCount",
      debugSignalServersList: "debugSignalServersList",
      turnApiDebugInfo: "turnApiDebugInfo",
    }),

    signalsFetch() {
      if (this.selectedSignal === "all") {
        return this.debugSignalServersList
      }
      let findedSignal = this.debugSignalServersList.find(
        (s) => s._key === this.selectedSignal
      )
      return findedSignal ? [findedSignal] : []
    },
  },
}
</script>

<style lang="scss" scoped>
.opaced {
  opacity: 0.6;
}
</style>
