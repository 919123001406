var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"data-testid":"container-debug-janus-servers"}},[_c('v-toolbar',[_c('v-card-title',[_vm._v("Debug Info Janus servers")]),_c('v-spacer')],1),_c('v-card-text',[_c('DataTableExtended',{ref:"refTableJanuses",staticClass:"elevation-0 border pt-3",attrs:{"headers":_vm.headers,"items":_vm.debugJanusServersList,"sortable":"","loading":_vm.showTableLoader,"no-data-text":_vm.showTableLoader ? 'Loading…' : 'No data available',"server-items-length":_vm.debugJanusServersListCount,"data-testid":"table-debug-janus-servers"},on:{"init-table-data":_vm.getDebugJanusesInfo,"update-search":(newS) => {
            this.filterSearch = newS
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"px-3"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('SelectSignalsAsync',{attrs:{"outlined":"","label":"Result from signal (s)","multiple":false,"item-text":"data.name","item-value":"_key","error-messages":_vm.errors.collect(`form-signal-servers.backup`),"data-vv-name":"backup","data-vv-validate-on":"change","data-vv-as":"Backup","field":"backup","menu-props":{ maxHeight: 304 },"prependEmptyOption":false,"prependCustomOptions":[
                  { _key: 'all', data: { name: 'All' } },
                ],"data-testid":"filter-signal"},on:{"change":function($event){return _vm.getDebugJanusesInfo()}},model:{value:(_vm.selectedSignal),callback:function ($$v) {_vm.selectedSignal=$$v},expression:"selectedSignal"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{style:(_vm.$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''),attrs:{"outlined":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":"","data-testid":"filter-search"},model:{value:(_vm.filterSearch),callback:function ($$v) {_vm.filterSearch=$$v},expression:"filterSearch"}})],1)],1)]},proxy:true},{key:`item.data.name`,fn:function({ item }){return [_c('span',{attrs:{"data-testid":"name"}},[_vm._v(" "+_vm._s(item.data.name)+" ")])]}},{key:`item.data.url`,fn:function({ item }){return [_c('span',{attrs:{"data-testid":"url"}},[_vm._v(" "+_vm._s(item.data.url)+" ")])]}},{key:`item.status`,fn:function({ item }){return [(typeof item.status === 'undefined')?_c('div',{staticClass:"primary--text"},[_c('span',{staticClass:"overline"},[_c('v-progress-circular',{staticClass:"mx-2",attrs:{"size":16,"indeterminate":"","color":"primary","data-testid":"progress-loading"}})],1),_c('span',{staticClass:"font-weight-regular"},[_vm._v("Loading")])]):(_vm.janusIsUnknown(item))?_c('div',{staticClass:"opaced",attrs:{"data-testid":"status"}},[_c('span',{staticClass:"overline"},[_c('i',{staticClass:"fa fa-circle mx-2"})]),_c('span',{staticClass:"font-weight-regular"},[_vm._v("Unknown")])]):_c('div',{class:_vm.janusIsActive(item)
                ? _vm.Pallette.statusText.active
                : _vm.Pallette.statusText.notActive,attrs:{"data-testid":"status"}},[_c('span',{staticClass:"overline",attrs:{"data-testid":"status-icon"}},[_c('i',{staticClass:"fa fa-circle mx-2"})]),_c('span',{staticClass:"font-weight-regular",attrs:{"data-testid":"status-text"}},[_vm._v(_vm._s(_vm.janusIsActive(item) ? "Active" : "Inactive"))])])]}},{key:`item.info.cpuAverage`,fn:function({ item }){return [_c('span',{attrs:{"data-testid":"cpu-average"}},[_vm._v(" "+_vm._s(_vm._f("toFixed")(_vm.showInfo(item, "cpuAverage")))+" ")])]}},{key:`item.info.memUsed`,fn:function({ item }){return [_c('span',{attrs:{"data-testid":"mem-used"}},[_vm._v(" "+_vm._s(_vm._f("toFixed")(_vm.showInfo(item, "memUsed")))+" ")])]}},{key:`item.info.loadUnits`,fn:function({ item }){return [_c('span',{attrs:{"data-testid":"load-units"}},[_vm._v(" "+_vm._s(_vm._f("toFixed")(_vm.showInfo(item, "loadUnits")))+" ")])]}},{key:`item.info.netUsed`,fn:function({ item }){return [_c('span',{attrs:{"data-testid":"net-used"}},[_vm._v(" "+_vm._s(_vm._f("toFixed")(_vm.showInfo(item, "netUsed")))+" ")])]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }